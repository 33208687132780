<!--
 * @Author: 月魂
 * @Date: 2021-11-16 16:16:03
 * @LastEditTime: 2022-09-20 18:09:38
 * @LastEditors: 月魂
 * @Description: 美术展详情
 * @FilePath: \silk-road\src\views\exhibition\detail.vue
-->
<template>
  <div class="detail">
    <van-nav-bar
      :title="getExhibition.title"
      left-arrow
      @click-left="handleBack"
    />
    <div class="header">
      <img :src="getExhibition.imgUrl" alt="" width="100%" class="banner">
      <div class="sum" v-if="getExhibition.sum">
        <img class="sum-img" src="http://image.wearetogether.com.cn/8th/assets/playSum.png" alt="">
        <div class="sum-text">{{ this.sum }} {{ this.$t('params.times') }}</div>
      </div>
    </div>
    <div class="cont">
      <div class="title">{{ getExhibition.title }}</div>
      <div class="card">
        <div class="item" v-if="getExhibition.host">
          <div class="item-left">{{ this.$t('params.host') }}: </div>
          <div class="item-right">{{ getExhibition.host }}</div>
        </div>
        <div class="item" v-else>
          <div class="item-left">{{ this.$t('params.organize') }}: </div>
          <div class="item-right">{{ getExhibition.organize }}</div>
        </div>
        <div class="item" v-show="getExhibition.undertake">
          <div class="item-left">{{ this.$t('params.undertake') }}: </div>
          <div class="item-right">{{ getExhibition.undertake }}</div>
        </div>
        <div class="item" v-show="getExhibition.coOrganized">
          <div class="item-left">{{ this.$t('params.coOrganized') }}: </div>
          <div class="item-right">{{ getExhibition.coOrganized }}</div>
        </div>
        <div class="item" v-show="getExhibition.support">
          <div class="item-left">{{ this.$t('params.support') }}: </div>
          <div class="item-right">{{ getExhibition.support }}</div>
        </div>
        <div class="item" v-show="getExhibition.thanks">
          <div class="item-left">{{ this.$t('params.thanks') }}: </div>
          <div class="item-right">{{ getExhibition.thanks }}</div>
        </div>
        <div class="item">
          <div class="item-left">{{ this.$t('params.exhibitionTime') }}: </div>
          <div class="item-right">{{ getExhibition.time }}</div>
        </div>
        <div class="item">
          <div class="item-left">{{ this.$t('params.exhibitionAddress') }}: </div>
          <div class="item-right">{{ getExhibition.address }}</div>
        </div>
      </div>
      <div class="online" v-show="getExhibition.outLink">
        <img v-if="getExhibition.qrUrl" :src="getExhibition.qrUrl" alt="" width="100%" style="display: block">
        <div class="link" @click="jump(getExhibition.outLink)">{{ this.$t('params.watchOnline') }}</div>
      </div>
      <!-- <div class="intro" v-if="getExhibition.des.length"> -->
      <div class="intro" >
        <div class="intro-cont" v-for="(des, index) in getExhibition.des" :key="index">{{ des }}</div>
      </div>
      
      <!-- 视频精彩剪辑 -->
      <!-- <div class="troupe" v-if="getExhibition.clip">
        <div class="title">{{ this.$t('params.clip') }}</div>
        <video :poster="`http://image.wearetogether.com.cn/seven-exhibition-clip-${getExhibition.id}.png`" controls style="display: block; width: 100%">
          <source :src="`http://image.wearetogether.com.cn/seven-exhibition-clip-${getExhibition.id}.mp4`">
        </video>
      </div>
      <div class="troupe" v-show="getExhibition.review">
        <div class="title">{{ this.$t('params.review') }}</div>
        <img v-for="(img, index) in getExhibition.review" :key="index" :src="`http://image.wearetogether.com.cn/seven-exhibition-poster-${getExhibition.id}-${img}.png`" alt="" class="review" @click="showImg(img)">
      </div> -->
    </div>
  </div>
</template>

<script>
import vue from 'vue';
import { NavBar } from 'vant'
import ImagePreview  from 'vant/lib/image-preview';
import 'vant/lib/image-preview/style';
import { handleBack } from '../../utils/util'
vue.use(ImagePreview).use(NavBar)

export default {
  name: 'ExhibitionDetail',
  data() {
    return {
      sum: 11000,
      // imgList: [],
      exhibitionId: null,
    }
  },
  mounted() {
    this.exhibitionId = this.$route.params.id;
    // 计算精彩回顾列表
    // const exhibition = this.getExhibition
    // if (exhibition.review && exhibition.review.length) {
    //   exhibition.review.map(item => {
    //     this.imgList.push(`${item}`)
    //   })
    // }
  },
  computed: {
    getExhibition() {
      let exhibitionNode = {};
      Object.values(this.$t('exhibition.list')).forEach(exhibition => {
        if (exhibition.id == this.exhibitionId) {
          exhibitionNode = exhibition
        }
      })
      this.wxShare(this.$t('share.title'), exhibitionNode.title)
      return exhibitionNode
    }
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(url) {
      window.location.href = url;
    },
    showImg(position) {
      if (window.innerWidth <= 1200) {
        ImagePreview({
          images: this.imgList,
          startPosition: position - 1
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: 100vh;
  background: #fcf8ed;
  .header {
    width: 100%;
    max-width: 1200PX;
    margin: auto;
    position: relative;
    .banner {
      display: block;
      width: 100%;
    }
    .sum {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      padding: 4px 10px;
      display: flex;
      align-items: center;
      color: #fff;
      .sum-img {
        display: block;
        width: 24px;
        height: 100%;
        margin-right: 3px;
      }
      .sum-text {
        line-height: 1;
        font-size: 12px;
      }
    }
  }
  .cont {
    width: 100%;
    max-width: 1200PX;
    margin: auto;
    padding: 16px 0;
    font-family: SourceHanSerifCN-Bold;
    color: #4D4D4D;
    .title {
      padding: 0 16px;
      font-size: 21px;
      font-weight: bold;
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      line-height: 22px;
      border-radius: 25px 25px 0 0;
      border-top: 1px solid #F5F5F5;
      overflow: hidden;
      .intro-cont {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .online {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30%;
        margin-right: 20px;
      }
      .link {
        font-size: 14px;
        text-decoration: underline;
        margin-top: 10px;
      }
    }
    .card {
      padding: 0 16px;
      margin-top: 16px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      font-family: SourceHanSansCN-Regular;
      .item {
        width: 100%;
        margin-top: 4px;
        display: flex;
        .item-left {
          margin-right: 0;
        }
        .item-right {
          flex: 1;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .troupe {
      .title {
        margin: 10px 0;
      }
      .review {
        display: block;
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}
</style>
